<template>
  <div class="report-list">
    <h3 class="model-title">核酸检测情况记录</h3>
    <van-tabs type="card" @click="timeChange">
      <van-tab title="当日"></van-tab>
      <van-tab title="本周"></van-tab>
      <van-tab title="本月"></van-tab>
      <van-tab title="自定义"></van-tab>
    </van-tabs>
    <div class="des">共{{total}}条记录</div>
    <div style="padding-bottom: 50px;">
      <van-list
          :immediate-check="false"
          v-model="loading"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad1"
      >
        <div class="check-list" v-for="(item,index) in list" :key="index" >
          <div class="item-choose">
<!--            <van-checkbox v-model="checked[item.id]" v-if="!hasReportBtn"></van-checkbox>-->
            <span style="position:absolute;left: 22px;top: -5px;">
              {{index+1}}
            </span>
          </div>

          <div class="item-info">
            {{item.info?item.info.name:item.infoId}}
          </div>
          <div class="item-check" @click="viewDetail(item)">
            <div class="item-user"><van-icon name="user-o" size="14" />{{item.user?item.user.name:"--"}}</div>
            <div style="font-size: 0.6rem;">{{item.createTime?item.createTime.substr(0,16):"--"}}</div>
          </div>

        </div>

      </van-list>
    </div>
    <div class="list-btn">
<!--      <van-button size="large" type="primary" @click="report">上报</van-button>
      <van-button  size="large" type="primary" @click="downLoad">下载</van-button>-->
      <van-button v-if="hasReportBtn" size="large" type="primary" @click="report">上报</van-button>
      <van-button v-else size="large" type="primary" @click="downLoad">下载</van-button>
    </div>
    <van-calendar v-model="calendarShow" type="range" @confirm="onConfirm" :min-date="minDate" :max-range="365" :style="{ height: '500px' }"  />


    <!--    检查项弹窗-->
    <van-popup v-model="reportPopup" position="right" :style="{ height: '100%',width:'100%' }"  get-container="#app" >
      <div class="model-title"><van-icon name="arrow-left" size="28" @click="reportPopup=false" />&nbsp;&nbsp;
        <span style="position:relative;top: -5px;">上报</span>
      </div>
      <report :dict="dict" :isShowData="recordData" :venue="makerDetail" ref="report" @closeReport="closeReport" @viewDetail="viewDetail"></report>
    </van-popup>
  </div>
</template>

<script>
import {formatDate,getStartEndByMonth,getWeekEndDate,getRecentMonth} from "@/api/time"
import {
  List,Cell,Col, Row,Icon,Popup,Checkbox,Button,Notify,Tab, Tabs,Calendar
} from 'vant';
import report from "./vacciItem"
export default {
  components:{report,
    [Calendar.name]: Calendar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Button.name]: Button,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Checkbox.name]: Checkbox,
    [Notify.name]: Notify,
    [Icon .name]: Icon
  },
  props:{

  },
  watch:{

  },
  data(){
    return {
      checked:{
      },
      loading:false,
      list:[],
      total:0,
      finished:false,
      error:false,
      refreshing:false,
      dict:{},
      recordPopup:false,
      recordData:{},
      defaultGroup:GlobalConfig.infoGroup,
      hasReportBtn:localStorage.getItem("wljpttagIds")==GlobalConfig.infoGroup?true:false,
      //hasReportBtn:GlobalConfig.userGroup==GlobalConfig.infoGroup?true:false,
      params:{
        startTime:"",
        endTime:"",
        size: 8,
        page: 0,
        person:"",
        infoId:localStorage.getItem("wljpttagIds")==GlobalConfig.infoGroup?localStorage.getItem("wljptinfoId"):"",
        // infoId:GlobalConfig.dev?GlobalConfig.infoId:"",
      },
      minDate:new Date(2021,9,1),
      // 整改窗
      reportPopup:false,
      makerDetail:{
        checkType:'1',
        id:localStorage.getItem("wljptinfoId"),
        name:localStorage.getItem("wljptinfoName"),
        // id:GlobalConfig.dev?GlobalConfig.infoId:"",
      },
      calendarShow:false
    }
  },
  methods: {
    timeChange(name, title){
      this.params.page = 0;
      if(name==1){
        this.params.startTime = getWeekEndDate()[0];
        this.params.endTime = getWeekEndDate()[1];
        this.getList();
      }else if(name==2){
        this.params.startTime = getStartEndByMonth()[0];
        this.params.endTime = getStartEndByMonth()[1];
        this.getList();
      }else if(name==0){
        this.params.startTime = formatDate(new Date()) + " 00:00:00";
        this.params.endTime = formatDate(new Date())+ "  23:59:59";
        this.getList();
      }else if(name==3){
        this.calendarShow = true;
      }
      console.log(this.params.startTime,this.params.endTime)

    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      this.params.page = 0;
      let [start, end] = date;
      this.calendarShow = false;
      this.params.startTime =this.formatDate(start);
      this.params.endTime =this.formatDate(end);
      this.getList();
    },
    // 获取检查项
    getDictList() {
      this.params.person = localStorage.getItem("wljptuser");
      this.$http({
        url: "/zuul/cultural/check/table/" + GlobalConfig.vacciableType,
        method: "get"
      }).then(res => {
        if (res.data.success) {
          res.data.data.typeList.map((item, index) => {
              this.dict[item.id] = {
                name: item.name,
                id: item.id,
                type:item.type,
                sort:index+1
              }
          })
        }
      })
    },
    // 获取需要整改检查记录
    getList() {
      this.finished = false;
      this.loading = true;
      let _this = this;
      this.list = [];
      this.$http({
        url: "/zuul/cultural/vacci",
        method: "get",
        params: this.params
      }).then(res => {
        // console.log(res)
        if (res.data.success && res.data.data) {
          _this.list = res.data.data.results;
          _this.total = res.data.data.total;
          _this.list.map(item=>{
            _this.$set(this.checked,item.id,false);
          })
        }
        if(_this.list.length<this.total){
          this.params.page++;
        }
        this.isFinished();
      }).finally(response => {
        this.loading = false;
      })
    },
    // 解析整改项
    parseRectify(str) {
      let res = "无整改项";
      if (str) {
        let typeJson = {};
        let arr = str.split(",");
        arr.map(item => {
          if(this.dict[item]){
            if (!typeJson[this.dict[item].type]) {
              typeJson[this.dict[item].type] = {};
            }
            typeJson[this.dict[item].type][item] = this.dict[item].name;
          }
        })
        res = "共" + arr.length + "项需整改 \n";
        for (let k in typeJson) {
          res += k + ":";
          let n = 0;
          for (let j in typeJson[k]) {

            if (n == 0) {
              res += typeJson[k][j]
            } else {
              res += "," + typeJson[k][j]
            }
            n++;
          }
          res += n + "项需整改。";
        }
      }
      return res;
    },
    // 解析检查结果
    parseJson(jsonstr,value){
      let str = "";
      if(jsonstr){
        let content= JSON.parse(jsonstr);
        str = content[value]
      }
      return str;
      // return value
    },
    viewDetail(item){
      this.recordData = item;
      this.reportPopup = true;
      /*console.log(item,"item");
      if(!item.id){
        item.id = item.check.id;
      }
      this.$http({
        url: "/zuul/cultural/vacci/" + item.id,
        method: "get"
      }).then(res=>{
        console.log(res);
        if(res.data.success){

          this.recordData = res.data.data;
        }
      })*/
    },
    isFinished(){
      if(this.list.length>=this.total){
        this.finished = true;
      }
    },
    onLoad1(){
      let _this = this;
      Promise.all([this.getOnLoadList()]).then(res=>{
        if(res[0].data.success && res[0].data.data.results){
          if(this.params.page>0){
            _this.list = _this.list.concat(res[0].data.data.results)
          }else{
            _this.list = res[0].data.data.results;
          }
          if(_this.list.length>= _this.total){
            _this.finished = true;
          }else{
            _this.params.page++;
          }
        }
      })
    },
    onRefresh(){
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad1();
      /*if(this.list.length<this.total){
        this.loading = true;
        this.params.page++;
        this.onLoad1();
      }else{
        this.loading = false;
        this.refreshing = false;
        this.finished = true;
      }*/

    },
    getOnLoadList(){
      let _this = this;
      this.loading = true;
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/cultural/vacci",
          method: "get",
          params: this.params
        }).then(res=>{
          resolve(res);

          /*if(res.data.success && res.data.data){
            _this.list = _this.list.concat(res.data.data.results)

          }*/
        }).catch(e=>{
          this.error = true;
        }).finally(e=>{
          this.loading = false;
        })
      })
    },
    downLoad(){
      /*let ids = []
      for(let k in this.checked){
        if(this.checked[k]){
          ids.push(k)
        }
      }
      if(ids.length==0){
        Notify({ type: 'warning', message: '至少选择一项！' });
        return;
      }*/
      //http://www.nanjingxinbu.com/zuul/cultural/vacci/export?startTime=2022-03-01%2000:00:00&endTime=2022-03-13%2017:08:05
      // let url = '/zuul/cultural/vacci/export?ids=' + ids.join(",");
      let url = '/zuul/cultural/vacci/export?startTime=' +  this.params.startTime  + "&endTime=" + this.params.endTime;
      window.open(url,"blank")

    },
    report(){
      this.reportPopup = true;
      this.recordData = {};
    },
    closeReport(){
      this.params.page = 0;
      this.getList();
      this.reportPopup = false;
    }
  },
  mounted(){
    this.timeChange(0);
    console.log("tagIds=" + localStorage.getItem("wljpttagIds"))
    let promise = new Promise(resolve => {
      this.getDictList()
      resolve()
    })
    promise.then(res =>{
      if(localStorage.getItem("wljpttagIds")==GlobalConfig.infoGroup){
        this.params = {
          startTime:this.params.startTime,
          endTime:this.params.endTime,
          size: this.params.size,
          page: 0,
          infoId: localStorage.getItem("wljptinfoId")
        }
      }else if(localStorage.getItem("wljpttagIds")==GlobalConfig.checkGroup){
        this.params = {
          startTime:this.params.startTime,
          endTime:this.params.endTime,
          size: this.params.size,
          page: 0
        }
      }else{
        this.params = {
          startTime:this.params.startTime,
          endTime:this.params.endTime,
          size:0,
          page: 0
        }
      }
      this.getList();
    })
  }
}
</script>

<style scoped lang="less">
.report-list /deep/.van-tabs__nav--card .van-tab{
  color: #349DFF;
  border-right: 1px solid #349DFF;
}
.report-list /deep/.van-tabs__nav--card{
  border: 1px solid #349DFF;
  border-right: none;
}
.report-list /deep/.van-tabs__nav--card .van-tab.van-tab--active{
  color:white;
  background: #349DFF;
}
.report-list /deep/.van-tabs__nav--card{
  margin: 0px;
}
.report-list{
  color: #515154;
  .model-title{
    letter-spacing: 2px;
    font-weight: 800;
    text-align: left;
    line-height: 45px;
    position: relative;
    padding: 0px 0px 0px 25px;
    margin: 0px;
    background: #f7f8fa;
    //background: #DFE0DF;
    .van-icon{
      position: absolute;
      left: 0px;
      top: 8px;
      font-weight: normal;
      color: #969799;
    }
  }
  .align-center{
    text-align: center;
  }
  .list-btn{
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: calc(100vw);
  }
}
.des{
  padding: 10px 20px;
  color: rgba(69, 90, 100, 0.6);
  background: #f7f8fa;
}
.check-list{
  padding: 10px 20px 2px;
  font-size: 0.8rem;
  border-bottom: 1px solid #ebedf0;;
  >div{
    display: inline-block;
  }
  .item-choose{
    position: relative;
    left: -10px;
    width: 50px;
    vertical-align: middle;
  }
  .item-index{
    width: 30px;
    position: relative;
    //top: -25px;
    vertical-align: middle;
  }
  .item-check{
    width: 100px;
    line-height: 1.2rem;
    vertical-align: middle;
    .item-user{
      padding: 5px 0px 2px 0px;
      .van-icon{
        position: relative;
        top: 2px;
        margin-right: 2px;
      }
    }
  }
  .item-btn{
    width: 60px;
    text-align:right;
    position: relative;
    .item-click{
      position: absolute;
      top: 2px;
      right: -20px;
      width: 30px;
      font-size: 1rem;
      color: #999;
    }
  }
  .item-check-res,.item-rectify{
    padding: 0 2px;
    vertical-align: middle;
    word-break: break-word;
    width: calc(100vw - 290px);
  }
  .item-check-res{
    position: relative;
    padding-left: 10px;

    //color: green;
    //cursor: pointer;
  }
  .item-info{
    //width: calc(100vw - 250px);
    width: calc(100vw - 200px);
    position: relative;
    left: -10px;
    vertical-align: middle;
    text-align: left;
  }

}
</style>
