<template>
  <div style="position:relative;width: calc(100vw);height: calc(100vh - 110px);padding-bottom: 60px;overflow-y: scroll;">

    <van-form @submit="onSubmit" @failed="failed" :label-width="labelWidth" ref="checkForm">

      <div class="form-title">{{venue.name}}</div>
      <div v-for="(obj, key) in dict" v-bind:key="key" class="form-item">
        <span class="label-tips">*</span>
        <van-field
            v-model="formData[formName[obj.id]]"
            :name="obj.id"
            :label="obj.sort +'.' + obj.name"
            placeholder="请输入数字"
            :rules="[{ required: true, message: '必填'}]"
            type="digit"
        >

        </van-field>
        <span class="item-unit">人</span>
        <div v-if="formName[obj.id]=='detect'">
          <van-field name="uploader"  label="上传文件" label-width="70">
            <template #input>
              <van-uploader @click-preview="lookDown" v-model="fileTest" :before-read="beforeRead" :upload-text="'支持图片和doc、ecxel'" accept=".xlsx,.xls,.csv,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*" :after-read="sendFile" :max-count="!btnShow?fileTest.length:6"  :deletable="btnShow" />
              <div v-if="(fileTest.length==0)&&!btnShow">无</div>
            </template>
          </van-field>
        </div>
      </div>
      <div class="form-label" style="padding-bottom: 5px;"><span></span>备注</div>
      <div>
        <van-field
            v-model="formData.remark"
            name="remark"
            label=""
            rows="1"
            autosize
            type="textarea"
            placeholder="请输入备注"
            :rules="[{ required: false, message: '必填'}]"
        />
      </div>
      <div class="form-label" style="padding-bottom: 5px;"><span></span>企业签名</div>
      <div>
        <div style="border: 1px solid #ebedf0;height: 80px;margin: 8px 16px;border-radius: 4px;" @click="openSign('checkedSign')">
          <div v-if="checkedSigned">
            <img :src="formData.signPic" height="80px">
          </div>
        </div>
      </div>

      <div style="position:fixed;bottom:0px;left:0px;width: calc(100vw);" v-if="btnShow">
        <van-row  type="flex" justify="center"  gutter="0">
          <van-col :span="24">
            <van-button size="large" type="primary" :loading="loading" native-type="submit">提交</van-button>
          </van-col>
        </van-row>

      </div>
    </van-form>
    <van-dialog v-model="show" title="确认提交？" show-cancel-button @cancel="cancleConfirm" @confirm="confirm">
      <van-notice-bar
          v-if="noticeShow"
          :scrollable="false"
          :text="des"
      />
    </van-dialog>
    <van-popup v-model="successPop" position="right" :style="{ height: '100%',width:'100%' }">

      <div >
        <div class="model-title">
          <van-icon name="arrow-left" size="28" />
        </div>
        <div class="success-icon">
          <van-icon name="passed" color="#00E68F" size="40" />
        </div>
        <div class="success-content">提交成功，{{seconds}}s<a href="javascript:void(0)" @click="goToMap">返回主页</a>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="signShow" position="bottom" :style="{ height: '100%' }" :closeable="true" get-container="#app" >
      <signature @getSign="getSign" :signer="signer" ref="signerContent"></signature>
    </van-popup>
  </div>
</template>

<script>
import signature from "../map/components/signature";
import {RenderCheckDict} from "@/api/dict"

import Compressor from 'compressorjs';
import {
  Toast,Button, Col, Row,Image,Icon,RadioGroup, Radio,Form,Field,Notify,Dialog,NoticeBar,Popup,Uploader
} from 'vant';
export default {
  components:{signature,
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Icon.name]: Icon ,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Image.name]: Image ,
    [Notify.name]: Notify,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
    [Uploader.name]: Uploader,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props:{
    venue:{
      type:Object,
      default: ()=>{
        return {
          name:"场馆1",
          id:"1"
        }
      }
    },
    dict:{
      type:Object,
      default: ()=> {
        return {}
      }
    },
    isShowData:{
      type:Object,
      default: ()=> {
        return {}
      }
    },
  },
  watch: {
    /*监听主题变化*/
    venue: {
      handler(n, o) {
        console.log(n,"report")
        this.venueData.infoId = n.id;
        this.successPop = false;
        this.seconds = 5;
      },
      deep: true
    },
    isShowData:{
      handler(n, o) {
        if(Object.keys(n).length>0){
          this.formData = n;
          this.btnShow = false;
          if(n.signPic){
            this.checkedSigned = true;
          }
          if(n.detectPic){
            this.fileTest = n.detectPic.split(",").map(url=>{
              let index1 = url.lastIndexOf(".");
              let fileType = url.substr(index1+1);
              let isImage = false;
              if(fileType=="png"|| fileType=="jpg" || fileType=="jpeg"|| fileType=="gif"|| fileType=="bmp"|| fileType=="webp"){
                isImage = true
              }
              return {
                url:url,
                isImage:isImage
              }
            })

          }
        }else{
          this.resetFormData();
          this.btnShow = true;
        }
      },
      deep: true,
      immediate:true
    }
  },
  data(){
    return {
      signShow:false,
      checkedSigned:false,
      btnShow:false,
      labelWidth:window.innerWidth - 165,
      venueData:{
        remark: GlobalConfig.dev?GlobalConfig.userName:localStorage.getItem("wljptuserName"),
        rectify:"",
        type:"yqfk",
        infoId:this.venue.id,
        checkerSign:"",
        checkedSign:"",
        status:-1,
        person:GlobalConfig.dev?GlobalConfig.openid:localStorage.getItem("wljptuser")
      },
      pics:[],
      fileList:{},
      formData:{
        infoId:this.venue.id,
        createBy:localStorage.getItem("wljptuser"),
        staff:undefined,//全体员工
        detect:undefined,//核酸人数
        notVaccinated:undefined,//未接种疫苗
        vaccinate:undefined,//接种一针
        vaccinateAll:undefined,//接种完成
        strengthen:undefined,//加强针
        signPic:"",
        detectPic:"",
        remark:""
      },
      formName:{
        "0018": "staff",
        "0019": "notVaccinated",
        "0020": "vaccinate",
        "0021": "vaccinateAll",
        "0022": "strengthen",
        "0023": "detect"
      },
      loading:false,
      noticeShow:false,
      show:false,
      successPop:false,
      des:"",
      seconds:5,
      timer:null,
      timeOuter:null,
      fileTest:[],
      signer:""
    }
  },
  methods:{
    resetFormData(){
      for(let k in this.formData){
        if(k!='infoId'){
          this.formData[k] = undefined;
        }

      }
      this.fileTest = [];
    },
    onSubmit(values){
      this.loading = true;
      this.show = true;
    },
    save(values){
      let _this = this;
      if(!this.formData.infoId){
        this.formData.infoId = localStorage.getItem("wljptinfoId");
      }
      this.formData.createBy = localStorage.getItem("wljptuser");

      this.formData.detectPic = this.fileTest.map(pic=>{
        console.log(pic,"save file url")
        return pic.url;
      }).join(",");
      this.$http({
        url: "/zuul/cultural/vacci/",
        method: "post",
        data:this.formData
      }).then(res=>{
        if(res.data.success){
          this.successPop = true;
          this.seconds = 5;
          _this.timer = setInterval(() => {
            _this.seconds--;
            if(_this.seconds<0){
              _this.successPop = false;
              window.clearInterval(_this.timer);
            }
          }, 1000);
          _this.timeOuter = setTimeout(function(){
            _this.resetFormData();
            _this.$emit("closeReport")
          },5000);

        }
      }).catch(error=>{
        Notify({ type: 'warning', message: '网络问题，请稍后再试' });
      }).finally(res=>{
        this.loading = false;
      })
    },
    failed(rule){
      if(rule.errors && rule.errors.length>0){
        this.$refs.checkForm.scrollToField(rule.errors[0].name)
      }
    },
    openSign(value){
      this.signer = value;
      this.signShow = true;
      this.$nextTick(()=>{
        this.$refs.signerContent.clearArea();
      })
    },
    getSign(value){
      this.formData.signPic = value.content;
      this.checkedSigned = true;
      this.signShow = false;
    },
    cancleConfirm(){
      this.show = false;
      this.loading = false;
    },
    confirm(){
      this.save();
    },

    goToMap(){
      this.successPop = false;
      clearTimeout(this.timeOuter);
      this.resetFormData();
      this.$emit("closeReport")
    },
    beforeRead(file,defaultQuqlity=0.6) {
      console.log(file,"upload file type",file.type)
      if(file.type.indexOf('image/') >-1){
        let fileSize = file.size/1024/1024;
        let quality = defaultQuqlity;
        /*if(file.type === 'image/jpeg' || file.type === 'image/png'){

        }*/
        if(fileSize<0.5){
          quality = 1;
        }else if(fileSize<1){
          quality = 0.8
        }else if(fileSize<2){
          quality = 0.6
        }else if(fileSize<4){
          quality = 0.3   // 0.4 0.24
        }else if(fileSize<6){
          quality = 0.15  // 0.1 0.06   0.2-0.17
        }else if(fileSize<8.1){
          quality = 0.1
        }else{
          return new Promise((resolve,reject)=>{
            Toast('文件大小不能超过 8M');
            reject({
              msg:"图片不能超过6M"
            })

          })
        }

        return new Promise((resolve) => {
          // compressorjs 默认开启 checkOrientation 选项
          // 会将图片修正为正确方向
          new Compressor(file, {
            quality: quality,
            success(result){
              resolve(result);
            },
            error(err) {
              console.log(err.message);
            },
          });
        });
      }else {
       return file;
      }

    },
    sendFile(file){
      // let fileType = file.file.type;
      let formData = new FormData();
      formData.append(new Date().getTime() + file.file.name,file.file);
      /* formData.append("timed",true);
       formData.append("filename",new Date().getTime() + file.file.name);*/
      this.$http({
        url: "/zuul/oss/file/cultural/upload",
        method: "post",
        headers: {'Content-Type': "multipart/form-data"},
        data:formData
      }).then(res=>{

        if(res.data.success){
          let url = res.data.data[0].viewUrl;
          let index1 = url.lastIndexOf(".");
          let fileType = url.substr(index1+1);
          let isImage = false;
          if(fileType=="png"|| fileType=="jpg" || fileType=="jpeg"|| fileType=="gif"|| fileType=="bmp"|| fileType=="webp"){
            isImage = true
          }
          console.log(isImage,url)
          this.fileTest[this.fileTest.length>0?(this.fileTest.length - 1):0].url = '/zuul' + (isImage?url:res.data.data[0].downloadUrl);
        }
      })
    },
    lookDown(file,detail){
      if(file.isImage){
        console.log("look image")
      }else{
        window.open("/zuul" + file.url,"blank")
      }
    }
  },
  mounted(){
    this.venueData.remark = localStorage.getItem("wljptuserName");
    this.venueData.person = localStorage.getItem("wljptuser");
  },
  beforeDestroy() {
    if(this.timer){
      window.clearInterval(this.timer);
    }
  },
}
</script>

<style scoped lang="less">

.form-title{
  color: rgba(69, 90, 100, 0.6);
  line-height: 45px;
  padding: 5px 16px 0px;
  background: #f7f8fa;
}
.form-label{
  padding: 10px 12px 0px;
  color: #646566;
}
.form-item{
  position: relative;
  padding: 0px 10px 0px 5px;
}
.label-tips{
  left: 16px;
  color: red;
  position: absolute;
  top: 15px;
  z-index: 1;
}
.item-unit{
  color: #969799;
  font-size: 13px;
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 1;
}
.confirm-item{
  padding: 5px 20px;
  color: #323233;
}
.model-title{
  letter-spacing: 2px;
  font-weight: 800;
  text-align: center;
  line-height: 50px;
  position: relative;
  .van-icon{
    position: absolute;
    left: 5px;
    top: 10px;
    font-weight: normal;
    color: #969799;
  }
}
.success-icon{
  text-align: center;
  position: relative;
  top: 120px;
}
.success-content{
  padding: 150px 20px;
  text-align: center;
}

/deep/ .form-image .van-field__error-message{
  top: 60px;
  left:90px;
}
/deep/ .van-cell__title{
  padding-left: 8px;
}
/deep/ .van-radio-group--horizontal{
  width: 150px;
}
/deep/ .van-field__error-message{
  position: absolute;
  top: 1px;
  left: -23px;
}
/deep/ .van-dialog{
  width: calc(100vw - 20px);
}
/deep/ .van-dialog__header{
  padding-bottom: 10px;
}
/deep/ .van-dialog__footer{
  padding: 10px 0px;
}
</style>
